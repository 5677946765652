export const GRAPHQL_SAFE_MAX_INT = 21000000;
export const GRAPHQL_SAFE_MIN_INT = -21000000;

export const emailLocalPartValidation = /^[a-z][a-z\d]*$/;

export const emailValidation =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailTruthyAndInvalid = (email?: string): boolean => {
  return email && email.length && !emailValidation.test(email);
};

export const emailTruthyAndValid = (email?: string): boolean => {
  return email && email.length > 0 && emailValidation.test(email);
};

export const stringOfMultipleEmailsIsValid = (
  multipleEmailsString?: string,
): boolean => {
  let validated = true;
  if (multipleEmailsString) {
    const emailsArray = multipleEmailsString.replaceAll(' ', '').split(',');
    for (const email of emailsArray) {
      if (email && emailTruthyAndInvalid(email)) {
        validated = false;
        break;
      }
    }
  }
  return validated;
};

export const phoneValidation =
  /^(\+[0-9]{0,3})? ?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const validImageFileTypes = '.png,.jpeg,.jpg,.gif,.webp';

export const websiteValidation = /^(ftp|http|https):\/\/[^ "]+$/;

export const isSafeInt = (num: number): boolean => {
  return num <= GRAPHQL_SAFE_MAX_INT && num >= GRAPHQL_SAFE_MIN_INT;
};

export const isNotNullNorUndefined = (target: any) => {
  return target !== null && target !== undefined;
};
