import React, {useRef, useEffect, useState} from 'react';
import styles from './toast.module.scss';
import {useToastContext, Toast, ToastType} from 'providers/toastProvider';
import {dts} from 'e2e/selectors';

/**
 * It handles the time for showing the toast, this time is different based on toast type
 * @param type ToastType
 * @returns Toast lifetime in milliseconds
 */
const getLingerTime = (type: ToastType = ToastType.info) => {
  return type === ToastType.error ? 5000 : 2000;
};

/**
 * Toast stack is the responsible for accessing the context and generate the toast elements,
 * also is the responsible about showing toast on first render
 * @returns Toast array
 */
const ToastStack = () => {
  const {toastSlices, persistedToastSlices, dismissPersistedToast} =
    useToastContext();
  const [toasts, setToast] = useState<Array<Toast>>([]);

  useEffect(() => {
    if (persistedToastSlices?.length) {
      setToast([...persistedToastSlices]);
      //Wait until show the  initial toast to clear the storage and local reference
      setTimeout(() => {
        dismissPersistedToast();
        setToast([]);
      }, getLingerTime());
    }
  }, [persistedToastSlices, setToast, dismissPersistedToast, setToast]);

  return (
    <div className={styles.toastStack}>
      {toastSlices.concat(toasts).map((slice, i) => {
        return <ToastSlice key={slice.id} topSlice={i === 0} {...slice} />;
      })}
    </div>
  );
};

/**
 * Component responsible for showing a Toast element and clear this element after a certain amount of time
 * @param Toast Toast props from context
 * @returns Toast component
 */
const ToastSlice = (props: {
  message: string | JSX.Element;
  id: string;
  type: ToastType;
  topSlice: boolean;
}) => {
  const {dismissToast} = useToastContext();
  const ref = useRef<HTMLDivElement>();

  const classNames = [styles.toast, styles[props.type]];
  if (props.topSlice) {
    classNames.push(styles.topSlice);
  }
  if (typeof props.message === 'string' && props.message.length > 100) {
    classNames.push(styles.toastMessageWideWidth);
  }
  useEffect(() => {
    setTimeout(
      () => {
        dismissToast(props.id);
      },
      getLingerTime(props.type) + 300,
    );
    setTimeout(() => {
      if (ref.current) {
        ref.current.className += ' ' + styles.leave;
      }
    }, getLingerTime(props.type));
  }, []);

  return (
    <div ref={ref} className={classNames.join(' ')}>
      <p data-test={dts.toast.message} className={styles.message}>
        {props.message}
      </p>
    </div>
  );
};

export default ToastStack;
