import React, {PropsWithChildren} from 'react';

interface ShowIfTrueProps {
  /**
   * Condition that must resolve to `true`
   * for the child component to be displayed.
   */
  condition: Boolean;

  children: React.ReactNode | React.ReactNode[];
}

/**
 * This is a helper HOC to hide/show UI elements based on a given condition.
 */
export const ShowIfTrue: React.FC<ShowIfTrueProps> = ({
  condition,
  children,
}): React.ReactNode => {
  const childrenArray = Array.isArray(children) ? children : [children];
  const fallback = childrenArray.find(
    (child: React.ReactNode) =>
      (child as React.ReactElement)?.type === Fallback,
  );
  const nonFallback = childrenArray.filter(
    (child) => (child as React.ReactElement)?.type !== Fallback,
  );

  if (!condition) {
    return fallback;
  }

  return nonFallback;
};

/**
 * Children wrapped with this component
 * will be rendered if ShowIfTrue condition is falsy.
 */
export const Fallback = ({children}: PropsWithChildren) => {
  return children;
};

export default ShowIfTrue;
