import gql from 'graphql-tag';
import {BASE_FEATURE_GATE_FIELDS, BASE_SELLER_FIELDS} from 'fragments/seller';
import {FeatureGate, Seller, TermsDocument} from 'types';

export interface GetSellerData {
  seller: Seller;
}

export const getSeller = gql`
  ${BASE_SELLER_FIELDS}
  query getSeller($id: String!) {
    seller(sellerId: $id) {
      ...BaseSellerFields
    }
  }
`;

export interface GetPlatformSellerData {
  platformSeller: Seller;
}

export const getPlatformSeller = gql`
  ${BASE_SELLER_FIELDS}
  query platformSeller {
    platformSeller {
      ...BaseSellerFields
    }
  }
`;

export interface GetPublicSellerData {
  publicSeller: Seller;
}

export const getPublicSeller = gql`
  query getSeller($id: String!) {
    publicSeller(sellerId: $id) {
      id
      logoImage
      primaryColor
      website
    }
  }
`;

export interface GetFeatureGatesData {
  featureGates: FeatureGate[];
}

export const getFeatureGates = gql`
  ${BASE_FEATURE_GATE_FIELDS}
  query getFeatureGates($sellerId: String) {
    featureGates(sellerId: $sellerId) {
      ...BaseFeatureGateFields
    }
  }
`;

export interface GetTermsDocumentsData {
  termsDocuments: TermsDocument[];
}

export interface GetCommercialTermsDocumentsData {
  commercialTermsDocuments: TermsDocument[];
}

export const getTermsDocuments = gql`
  query getTermsDocuments($sellerId: String!) {
    termsDocuments(sellerId: $sellerId) {
      id
      name
      renewalTerms
      documentType
      createdAt
      isDefault
    }
  }
`;

export const getCommercialTermsDocuments = gql`
  query getCommercialTermsDocuments($sellerId: String!) {
    commercialTermsDocuments(sellerId: $sellerId) {
      id
      name
      renewalTerms
      documentType
      createdAt
      isDefault
    }
  }
`;
