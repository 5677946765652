import React, {useMemo, useState} from 'react';
import styles from './loginPanel.module.scss';
import Spinner from 'components/ui/spinner';
import Button from 'components/ui/button';
import {
  logIn as LogInMutation,
  LogInMutationData,
  useCreateAuthPayload,
} from 'mutations/auth';
import Link from 'next/link';
import {useMutation} from 'hooks/useQuery';
import {emailTruthyAndValid} from 'utils/validations';
import useIssueText from 'hooks/useIssueText';
import EditInput from 'components/ui/editInput';
import {useRouter} from 'next/router';
import {Event, identify, track} from 'utils/events';
import {dts} from 'e2e/selectors';
import {updateSessionUser} from 'hooks/useSessionUser';
import OktaIcon from 'public/partners/okta-icon.svg';
import OktaLogo from 'public/partners/okta-logo.svg';
import FeatureGate, {FeatureCode} from 'components/featureGates';

function useDoLogin(
  email: string,
  password: string,
  onLogin?: () => void,
  onLoginNavCallback?: () => void,
) {
  const {error, setError, clearIssue} = useIssueText();
  const [logIn, {loading}] = useMutation<LogInMutationData>(LogInMutation);
  const router = useRouter();

  const payload = useCreateAuthPayload(email, password);

  const onSubmit = (e) => {
    e.preventDefault();
    clearIssue();
    logIn({
      variables: {payload},
      update: updateSessionUser,
    })
      .then(async (response) => {
        const data = response.data;
        if (data) {
          if (!data.logIn?.success) {
            setError(
              'The email and password combination you submitted was invalid. Please try again.',
            );
          } else if (data.logIn?.success) {
            identify(data.logIn?.id, {
              email,
              sellerId: data?.logIn?.seller?.id,
              sellerName: data?.logIn?.seller?.name,
            });
            track(Event.LOGIN);

            clearIssue();
            onLogin && onLogin();

            if (data.logIn?.redirectPath) {
              router.push(data.logIn?.redirectPath);
            } else {
              onLoginNavCallback && onLoginNavCallback();
            }
          }
        }
      })
      .catch((responseError) => {
        console.error('responseError', responseError);
        setError(
          'Log In was unsuccessful due to a server error. Please try again later or contact support at support@salesbricks.com',
        );
      });
  };

  return {loading, error, onSubmit};
}

const LoginPanel = (props: {
  className?: string;
  onLogin?: () => void;
  onLoginNavCallback?: () => void;
  initialEmail?: string;
  hideTitle?: boolean;
}) => {
  const {onLogin, className, initialEmail, hideTitle} = props;
  const [password, setPassword] = useState<string>();
  const [email, setEmail] = useState<string>(initialEmail);

  const emailValid = useMemo(() => {
    return emailTruthyAndValid(email);
  }, [email]);

  // No this isn't our real required password length but it's enough to
  // do for now
  const passwordValid = password && password.length > 3;

  const {onSubmit, loading, error} = useDoLogin(email, password, onLogin);

  const forgotPasswordPath = email
    ? `/forgot-password/?email=${email}`
    : `forgot-password`;

  const oktaButton = (
    <div className={styles.ssoButtonTextWrapper}>
      <div>Okta</div>
      <div className={styles.ssoButtonLogos}>
        <OktaIcon />
        <OktaLogo />
      </div>
    </div>
  );

  return (
    <>
      <form
        className={[styles.panel, className].join(' ')}
        onSubmit={onSubmit}
        data-test={dts.components.loginPanel.container}>
        {!hideTitle && <h1 className={styles.title}>Log In</h1>}
        <EditInput
          data-test={dts.components.loginPanel.email}
          label={'Email'}
          inputType={'email'}
          inputValue={email}
          error={email && !emailValid}
          message={email && !emailValid ? 'Invalid email' : ''}
          onChange={setEmail}
          placeholder="example@email.com"
        />
        <EditInput
          data-test={dts.components.loginPanel.password}
          label={'Password'}
          inputType={'password'}
          inputValue={password}
          onChange={setPassword}
        />
        <ErrorText text={error} />
        <Button
          data-test={dts.components.loginPanel.submit}
          className={styles.logInButton}
          disabled={!emailValid || !passwordValid}
          title={'Log In'}>
          Log In
        </Button>
        <Link
          href={forgotPasswordPath}
          data-test={dts.components.loginPanel.forgot}
          className={styles.forgotPasswordButton}>
          Forgot password?
        </Link>
        {loading && (
          <div className={styles.spinnerOverlay}>
            <Spinner delay={true} />
          </div>
        )}
      </form>
      <FeatureGate featureCode={FeatureCode.oktaSso}>
        <div className={styles.horizontalRule}></div>
        <p className={styles.ssoSectionHeader}>Or continue with</p>
        <Link href={'/sso/okta'} passHref legacyBehavior>
          <Button
            className={styles.ssoButton}
            buttonType={'tertiary'}
            fullWidth
            title="Okta">
            {oktaButton}
          </Button>
        </Link>
      </FeatureGate>
    </>
  );
};

export const ErrorText = (props: {text?: string}) => {
  const {text} = props;
  if (!text) {
    return null;
  }

  return (
    <p data-test={dts.components.loginPanel.error} className={styles.errorText}>
      {text}
    </p>
  );
};

export default LoginPanel;
