import React, {useEffect} from 'react';
import styles from 'styles/login.module.scss';
import {useRouter} from 'next/router';
import LoginPanel from 'components/loginPanel';
import {Category, page} from 'utils/events';
import ToastProvider, {
  ToastType,
  useToastContext,
} from 'providers/toastProvider';
import ToastStack from 'components/ui/toast';
import FeatureGate, {FeatureCode} from 'components/featureGates';
import AuthLayout from 'components/ui/authLayout';
import useSessionUser from 'hooks/useSessionUser';

const RedirectIfAuthed = ({children}) => {
  const router = useRouter();
  // GOD-6080: check to see if we already have a session, if we do redirect to admin
  const [user, loading] = useSessionUser();

  useEffect(() => {
    if (!user) return;
    router.push('/admin/orders');
  }, [user]);

  // if we have finished loading (explicitly checking for false) and there is no user
  // then we can render the LoginPage, otherwise we should return null to avoid flashes
  // and allow time for the router to push
  if (loading === false && !user) return children;

  return null;
};

const LoginPage = () => {
  const router = useRouter();
  const {showToast} = useToastContext();
  const {afterSuccess, source} = router.query;

  useEffect(() => {
    page(Category.PUBLIC, 'Login');
  }, []);

  useEffect(() => {
    if (source === 'reset-password') {
      showToast('Password successfully changed', ToastType.success);
      window.history.replaceState(
        {...router.query, source: undefined},
        'Login',
        '/login',
      );
    }
  }, [source]);

  return (
    <AuthLayout>
      <LoginPanel
        onLogin={() => {
          router.push((afterSuccess as string) || '/admin');
        }}
      />
      <p className={styles.conditions}>
        By clicking “Log in”
        <FeatureGate featureCode={FeatureCode.oktaSso}>
          {' '}
          or continuing with an SSO provider
        </FeatureGate>
        , you agree to Salesbricks’{' '}
        <a href={'/terms-and-conditions'}>Terms and Conditions</a> and{' '}
        <a href={'/terms-and-conditions'}>Privacy Policy</a>.
      </p>
    </AuthLayout>
  );
};

const Wrapped = () => (
  <ToastProvider>
    <ToastStack />
    <RedirectIfAuthed>
      <LoginPage />
    </RedirectIfAuthed>
  </ToastProvider>
);

export default Wrapped;
