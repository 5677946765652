import {v4} from 'uuid';

// enums of the events that we track
export enum Event {
  // account
  LOGIN = 'LOGIN',
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',

  // integration events
  SALESFORCE_INTEGRATION_UPDATED = 'SALESFORCE_INTEGRATION_UPDATED',
  HUBSPOT_INTEGRATION_UPDATED = 'HUBSPOT_INTEGRATION_UPDATED',
  STRIPE_INTEGRATION_UPDATED = 'STRIPE_INTEGRATION_UPDATED',
  XERO_INTEGRATION_UPDATED = 'XERO_INTEGRATION_UPDATED',
  SLACK_INTEGRATION_UPDATED = 'SLACK_INTEGRATION_UPDATED',
  QUICKBOOKS_INTEGRATION_UPDATED = 'QUICKBOOKS_INTEGRATION_UPDATED',
  MAGIC_LINK_COPIED = 'MAGIC_LINK_COPIED',

  // team
  TEAM_MEMBER_CREATED = 'TEAM_MEMBER_CREATED',
  TEAM_MEMBER_UPDATED = 'TEAM_MEMBER_UPDATED',
  ACTIVATE_TEAM_MEMBER_CLICKED = 'ACTIVATE_TEAM_MEMBER_CLICKED',
  RESET_TEAM_MEMBER_PASSWORD_CLICKED = 'RESET_TEAM_MEMBER_PASSWORD_CLICKED',
  TEAM_MEMBER_DEACTIVATED = 'TEAM_MEMBER_DEACTIVATED',
  TEAM_MEMBER_REACTIVATED = 'TEAM_MEMBER_REACTIVATED',

  // product
  NEW_PRODUCT_CLICKED = 'NEW_PRODUCT_CLICKED',
  PRODUCT_CREATED = 'PRODUCT_CREATED',
  PRODUCT_FAQ_CREATED = 'PRODUCT_FAQ_CREATED',
  PRODUCT_LEGAL_UPDATED = 'PRODUCT_LEGAL_UPDATED',
  PRODUCT_FINANCE_UPDATED = 'PRODUCT_FINANCE_UPDATED',
  PRODUCT_IMAGE_UPDATED = 'PRODUCT_IMAGE_UPDATED',
  PRODUCT_FAQ_UPDATED = 'PRODUCT_FAQ_UPDATED',
  PRODUCT_UPDATED = 'PRODUCT_UPDATED',

  NEW_PLAN_CLICKED = 'NEW_PLAN_CLICKED',
  PRODUCT_PLAN_CLICKED = 'PRODUCT_PLAN_CLICKED',
  UPDATE_PRODUCT_CANCELED = 'UPDATE_PRODUCT_CANCELED',
  CREATE_PRODUCT_CANCELLED = 'CREATE_PRODUCT_CANCELLED',

  EDIT_BRICKS_CLICKED = 'EDIT_BRICKS_CLICKED',

  PRODUCT_ACTIVATED = 'PRODUCT_ACTIVATED',
  PRODUCT_DEACTIVATED = 'PRODUCT_DEACTIVATED',

  TERMINATE_ORDER_REVERTED = 'TERMINATE_ORDER_REVERTED',
  ORDER_TERMINATED = 'ORDER_TERMINATED',
  ORDER_MANUAL_CLOSED = 'ORDER_MANUAL_CLOSED',
  ORDER_LOST_CLOSED = 'ORDER_LOST_CLOSED',
  ORDER_DELETE_CLOSED = 'ORDER_DELETE_CLOSED',
  ORDER_CANCELED = 'ORDER_CANCELED',

  // customers
  CUSTOMERS_TAB_VIEWED = 'CUSTOMERS_TAB_VIEWED',
  INSIGHTS_TAB_VIEWED = 'INSIGHTS_TAB_VIEWED',
  USAGE_TAB_VIEWED = 'USAGE_TAB_VIEWED',

  // checkout
  ORDER_VIEWED = 'ORDER_VIEWED',
  COMPANY_VIEWED = 'COMPANY_VIEWED',
  PAYMENT_INFO_VIEWED = 'PAYMENT_INFO_VIEWED',
  ORDER_COMPLETED = 'ORDER_COMPLETED',
  ORDER_SHARED_WITH_SIGNER = 'ORDER_SHARED_WITH_SIGNER',
  SHARE_LINK_COPIED = 'SHARE_LINK_COPIED',
  SHARE_LINK_SENT = 'SHARE_LINK_SENT',
  POINT_OF_CONTACT_TOGGLED = 'POINT_OF_CONTACT_TOGGLED',
  BILLING_SCHEDULE_VIEWED = 'BILLING_SCHEDULE_VIEWED',
  PRICING_BREAKDOWN_VIEWED = 'PRICING_BREAKDOWN_VIEWED',
  TERMS_VIEWED = 'TERMS_VIEWED',
  CHECKOUT_CTA_CLICKED = 'CHECKOUT_CTA_CLICKED',

  // plans
  PUBLISH_PLAN_CLICKED = 'PUBLISH_PLAN_CLICKED',
  DRAFT_PLAN_CREATED = 'DRAFT_PLAN_CREATED',
  DRAFT_PLAN_UPDATED = 'DRAFT_PLAN_UPDATED',
  PLAN_PUBLISHED = 'PLAN_PUBLISHED',
  CREATE_PLAN_CANCELED = 'CREATE_PLAN_CANCELED',
  UPDATE_PLAN_CANCELED = 'UPDATE_PLAN_CANCELED',
  DONE_EDITING_BRICKS_CLICKED = 'DONE_EDITING_BRICKS_CLICKED',
  CANCEL_EDITING_BRICKS_CLICKED = 'CANCEL_EDITING_BRICKS_CLICKED',

  // order and order creation
  ORDER_STAGE_FILTER_CLICKED = 'ORDER_STAGE_FILTER_CLICKED',
  NEW_ORDER_CLICKED = 'NEW_ORDER_CLICKED',
  NEW_ORDER_STARTED = 'NEW_ORDER_STARTED',
  BRICK_PRICING_UPDATED = 'BRICK_PRICING_UPDATED',
  PLAN_PRICING_UPDATED = 'PLAN_PRICING_UPDATED',
  CUSTOMER_INFO_PREPOPULATED = 'CUSTOMER_INFO_PREPOPULATED',
  ORDER_UPDATED = 'ORDER_UPDATED',
  ORDER_CREATED = 'ORDER_CREATED',
  SHOW_TERMS_DURING_CHECKOUT_TOGGLED = 'SHOW_TERMS_DURING_CHECKOUT_TOGGLED',
  SHOW_COMMERCIAL_TERMS_TOGGLED = 'SHOW_COMMERCIAL_TERMS_TOGGLED',
  SHOW_MSA_TOGGLED = 'SHOW_MSA_TOGGLED',
  ORDER_CREATION_CANCELED = 'ORDER_CREATION_CANCELED',
  ORDER_UPDATE_CANCELED = 'ORDER_UPDATE_CANCELED',
  DOWNLOAD_ORDER_FORM_CLICKED = 'DOWNLOAD_ORDER_FORM_CLICKED',
  DELETE_THIS_ORDER_CLICKED = 'DELETE_THIS_ORDER_CLICKED',
  ORDER_DELETED = 'ORDER_DELETED',
  PREVIEW_ORDER_MODAL_OPENED = 'PREVIEW_ORDER_MODAL_OPENED',

  // these are hosted externally so we want to
  // mimic the page view event
  VIEW_PRICING = 'VIEW_PRICING',

  // salesforce integration
  SALESFORCE_LOGGED_IN = 'SALESFORCE_LOGGED_IN',
  SALESFORCE_SELECT_INTEGRATION_LEVEL = 'SALESFORCE_SELECT_INTEGRATION_LEVEL',
  SALESFORCE_MAP_FIELDS = 'SALESFORCE_MAP_FIELDS',

  // lite
  LITE_CLICK_BUILD_ORDER_FORM = 'CLICK_BUILD_ORDER_FORM',
  LITE_CLICK_GENERATE_ORDER_FORM = 'CLICK_GENERATE_ORDER_FORM',
  LITE_CLICK_CANCEL_GENERATE_ORDER_FORM = 'CLICK_CANCEL_GENERATE_ORDER_FORM',
  LITE_CLICK_SOUNDS_GREAT_SIGNUP = 'CLICK_SOUNDS_GREAT_SIGNUP',
  LITE_CLICK_DOWNLOAD = 'CLICK_DOWNLOAD',
  LITE_CLICK_SIGN_UP = 'CLICK_SIGN_UP',
  LITE_CLICK_SEND_LINK = 'CLICK_SEND_LINK',
  LITE_CLICK_NEW_ORDER = 'CLICK_NEW_ORDER',
  LITE_CLICK_ADD_TO_ORDER_FORM = 'CLICK_ADD_TO_ORDER_FORM',
  LITE_CLICK_CANCEL_ADD_TO_ORDER_FORM = 'CLICK_CANCEL_ADD_TO_ORDER_FORM',

  LITE_UPDATE_DOC_TYPE = 'UPDATE_DOC_TYPE',
  LITE_UPDATE_REF_ID = 'UPDATE_REF_ID',
  LITE_UPDATE_CUSTOMER_COMPANY_NAME = 'UPDATE_CUSTOMER_COMPANY_NAME',
  LITE_UPDATE_SELLER_COMPANY_NAME = 'UPDATE_SELLER_COMPANY_NAME',
  LITE_UPDATE_FIRST_NAME = 'UPDATE_FIRST_NAME',
  LITE_UPDATE_LAST_NAME = 'UPDATE_LAST_NAME',
  LITE_UPDATE_EMAIL = 'UPDATE_EMAIL',
  LITE_UPDATE_BILLING_SCHEDULE = 'UPDATE_BILLING_SCHEDULE',
  LITE_UPDATE_PAYMENT_TERMS = 'UPDATE_PAYMENT_TERMS',
  LITE_UPDATE_TERMS_DOC = 'UPDATE_TERMS_DOC',
  LITE_UPDATE_URL = 'UPDATE_URL',
  LITE_UPDATE_AGREEMENT_TERM = 'UPDATE_AGREEMENT_TERM',
  LITE_UPDATE_PRODUCT_DEFINITIONS = 'UPDATE_PRODUCT_DEFINITIONS',
  LITE_UPDATE_COMMERCIAL_TERMS = 'UPDATE_COMMERCIAL_TERMS',
  LITE_UPDATE_STATEMENT_OF_WORK = 'UPDATE_STATEMENT_OF_WORK',

  // order insights
  ORDER_INSIGHT_PLAN_UPDATED = 'PLAN_UPDATED',
  ORDER_INSIGHT_BRICK_ADDED = 'BRICK_ADDED',
  ORDER_INSIGHT_BRICK_REMOVED = 'BRICK_REMOVED',
  ORDER_INSIGHT_BRICK_UPDATED = 'BRICK_UPDATED',
  ORDER_INSIGHT_TRANSACTION_METHOD_SELECTED = 'TRANSACTION_METHOD_SELECTED',
  ORDER_INSIGHT_ORDER_FORM_SIGNED = 'ORDER_FORM_SIGNED',
  ORDER_INSIGHT_ORDER_SHARED = 'ORDER_SHARED',
  ORDER_INSIGHT_SHARE_LINK_OPENED = 'SHARE_LINK_OPENED',
  ORDER_INSIGHT_POINT_OF_CONTACT_ADDED = 'POINT_OF_CONTACT_ADDED',
  ORDER_INSIGHT_STATUS_UPDATED_TO_AWAITING_COUNTERSIGN = 'ORDER_STATUS_UPDATED_TO_AWAITING_COUNTERSIGN',
  ORDER_INSIGHT_STATUS_UPDATED_TO_CLOSED_WON = 'ORDER_STATUS_UPDATED_TO_CLOSED_WON',

  // billing and payments
  SEND_INVOICE_BUTTON_CLICKED = 'SEND_INVOICE_BUTTON_CLICKED',
  SEND_INVOICE_SUBMITTED = 'SEND_INVOICE_SUBMITTED',
  SEND_INVOICE_CANCELLED = 'SEND_INVOICE_CANCELLED',
  RECORD_PAYMENT_BUTTON_CLICKED = 'RECORD_PAYMENT_BUTTON_CLICKED',
  RECORD_PAYMENT_BUTTON_CANCELLED = 'RECORD_PAYMENT_BUTTON_CANCELLED',
  RECORD_PAYMENT_BUTTON_SUBMITTED = 'RECORD_PAYMENT_BUTTON_SUBMITTED',
  ADD_INVOICE_ADJUSTMENT_BUTTON_CLICKED = 'ADD_INVOICE_ADJUSTMENT_BUTTON_CLICKED',
  ADD_INVOICE_ADJUSTMENT_SUBMITTED = 'ADD_INVOICE_ADJUSTMENT_SUBMITTED',
  ADD_INVOICE_ADJUSTMENT_CANCELLED = 'ADD_INVOICE_ADJUSTMENT_CANCELLED',
  VIEW_INVOICE_BUTTON_CLICKED = 'VIEW_INVOICE_BUTTON_CLICKED',
  BILLING_AND_PAYMENTS_PAGE_OPENED = 'BILLING_AND_PAYMENTS_PAGE_OPENED',
  BILLING_STATEMENT_ROW_EXPANDED = 'BILLING_STATEMENT_ROW_EXPANDED',
  BILLING_STATEMENT_PAGE_OPENED = 'BILLING_AND_PAYMENTS_PAGE_OPENED',

  // charts and reports
  CHART_CLICKED = 'CHART_CLICKED',
  DOWNLOAD_REPORT_CLICKED = 'DOWNLOAD_REPORT_CLICKED',
  REPORT_DATE_RANGE_SELECTED = 'REPORT_DATE_RANGE_SELECTED',
  CHARTS_DATE_RANGE_SELECTED = 'CHARTS_DATE_RANGE_SELECTED',
  REPORT_FILTERS_APPLIED = 'REPORT_FILTERS_APPLIED',
  REPORT_CLICKED = 'REPORT_CLICKED',
  TAX_DETAILS_MODAL_OPENED = 'TAX_DETAILS_MODAL_OPENED',

  // Custom domain
  CONNECT_DOMAIN_CLICKED = 'CONNECT_DOMAIN_CLICKED',
  EDIT_EMAIL_ADDRESSES_CLICKED = 'EDIT_EMAIL_ADDRESSES_CLICKED',
  EMAIL_ADDRESSES_SUBMITTED = 'EMAIL_ADDRESSES_SUBMITTED',
  CHECK_CONNECTION_CLICKED = 'CHECK_CONNECTION_CLICKED',

  // Email Notifications
  EMAIL_NOTIFICATION_STATUS_UPDATED = 'EMAIL_NOTIFICATION_STATUS_UPDATED',
  EMAIL_NOTIFICATION_DOCUMENT_REFERENCE_VIEWED = 'EMAIL_NOTIFICATION_DOCUMENT_REFERENCE_VIEWED',
  EMAIL_NOTIFICATION_SETTINGS_VIEWED = 'EMAIL_NOTIFICATION_SETTINGS_VIEWED',
}

// enums are categories that we can attach page views to
export enum Category {
  // not required to be logged in
  PUBLIC = 'PUBLIC',
  ORDERS = 'ORDERS',
  CUSTOMERS = 'CUSTOMERS',
  TEAM = 'TEAM',
  PRODUCTS = 'PRODUCTS',
  BRICKS = 'BRICKS',
  DISCOUNTS = 'DISCOUNTS',
  SETTINGS = 'SETTINGS',
  LITE = 'LITE',
  ORDER_INSIGHT = 'ORDER_INSIGHT',
  CHARTS_AND_REPORTS = 'CHARTS_AND_REPORTS',
  BUYER_EXPERIENCE = 'BUYER_EXPERIENCE',
  ORDER_CREATION = 'ORDER_CREATION',
  CUSTOM_DOMAIN = 'CUSTOM_DOMAIN',
  NOTIFICATION_MANAGEMENT = 'NOTIFICATION_MANAGEMENT',
}

export enum ProductsSubcategory {
  DETAILS = 'DETAILS',
  PLANS = 'PLANS',
  FINANCE = 'FINANCE',
  PRICING_PAGE = 'PRICING_PAGE',
}

export enum ChartsAndReportsSubcategory {
  REVENUE_REPORT = 'REVENUE_REPORT',
  RAW_DATA_REPORT = 'RAW_DATA_REPORT',
  BOOKINGS_REPORT = 'BOOKINGS_REPORT',
  RENEWAL_REPORT = 'RENEWAL_REPORT',
  INVOICE_RECEIVABLES_REPORT = 'INVOICE_RECEIVABLES_REPORT',
  TAX_REMITTANCE_REPORT = 'TAX_REMITTANCE_REPORT',
  WATERFALL_REPORT = 'WATERFALL_REPORT',
  CUSTOMER_COUNT_REPORT = 'CUSTOMER_COUNT_REPORT',
  COLLECTIONS_CHART = 'COLLECTIONS_CHART',
  REVENUE_CHART = 'REVENUE_CHART',
  BOOKINGS_CHART = 'BOOKINGS_CHART',
  CUSTOMERS_CHART = 'CUSTOMERS_CHART',
  RENEWALS_CHART = 'RENEWALS_CHART',
  INVOICING_AND_PAYMENTS_CHART = 'INVOICING_AND_PAYMENTS_CHART',
}

export enum BuyerExperienceSubcategory {
  BUILD = 'BUILD',
  ADDRESS = 'ADDRESS',
  CHECKOUT = 'CHECKOUT',
  THANK_YOU = 'THANK_YOU',
}

export enum SettingsSubcategory {
  COMPANY = 'COMPANY',
  TEAM = 'TEAM',
  BRANDING = 'BRANDING',
  LEGAL = 'LEGAL',
  FINANCE = 'FINANCE',
  INTEGRATIONS = 'INTEGRATIONS',
}

export enum NotificationManagementSubcategory {
  MUTED = 'MUTED',
  UNMUTED = 'UNMUTED',
}

// largely - browser action
export enum Action {
  // referring to the classic rectangle button with rounded corners, cta copy, and no svg
  BUTTON_CLICKED = 'BUTTON_CLICKED',
  // The small icons are technically buttons as well, but from a UX perspective clicking
  // on an icon is distinct from clicking on a standard button.
  // It may be interesting to know, say, if certain icons are not being clicked
  // due to it not being clear what it's for
  ICON_CLICKED = 'ICON_CLICKED',
  ELEMENT_CLICKED = 'ELEMENT_CLICKED',
  OPTION_SELECTED = 'OPTION_SELECTED',
  VIEW = 'VIEW',
  INPUT_COMPLETED = 'INPUT_COMPLETED',
  CHECKBOX_TOGGLED = 'CHECKBOX_TOGGLED',
}

export enum ClickType {
  // largely, matching CRUD calls
  CREATE = 'CREATE',
  GET = 'GET',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',

  // other browser click event types
  DOWNLOAD = 'DOWNLOAD',
  REDIRECT = 'REDIRECT',
  CANCEL = 'CANCEL',
  COPY_LINK = 'COPY_LINK',
  SUBMIT = 'SUBMIT',
}

export enum OptionType {
  DATE_RANGE = 'DATE_RANGE',
  STRING = 'STRING',
  BUTTON = 'BUTTON', // not to be confused with stand-alone buttons
}

export enum ViewType {
  MODAL = 'MODAL',
  PANE = 'PANE',
  ELEMENT = 'ELEMENT',
  TAB = 'TAB',
}

export enum InputType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
}

// setup is a sync once closure that allows us to
// lazy load the rudder sdk which is only available
// client side. it allows us to call
//  import getRudder from 'utils/events'; (await getRudder).track(...)
const getRudder = (() => {
  let done = false;
  let rudder = null;

  return async () => {
    if (
      !process.env.NEXT_PUBLIC_RUDDER_DATA_PLANE ||
      !process.env.NEXT_PUBLIC_RUDDER_WRITE_KEY
    ) {
      return {
        identify: () => {},
        setAnonymousId: (_: string) => {},
        getAnonymousId: (): string => {
          return v4();
        },
        page: () => {},
        track: () => {},
      };
    }

    if (done) return rudder;

    // initialise rudder
    rudder = await import('rudder-sdk-js');

    rudder.load(
      process.env.NEXT_PUBLIC_RUDDER_WRITE_KEY,
      process.env.NEXT_PUBLIC_RUDDER_DATA_PLANE,
      {
        integrations: {All: false, Amplitude: true},
        sessions: {
          autoTrack: true,
        },
      },
    );

    return rudder;
  };
})();

export const identify = async (id: string, traits: Record<string, any>) => {
  const rudder = await getRudder();
  rudder.identify(id, {
    ...traits,
    env: process.env.NEXT_PUBLIC_ENV,
    version: process.env.NEXT_PUBLIC_VERSION,
  });
};

export const getAnonymousId = async (): Promise<string> => {
  const rudder = await getRudder();
  return await rudder.getAnonymousId();
};

export const setAnonymousId = async (id: string) => {
  const rudder = await getRudder();
  rudder.setAnonymousId(id);
};

export const track = async (
  event: Event,
  properties: Record<string, any> = {},
) => {
  const rudder = await getRudder();
  rudder.track(event, {
    ...properties,
    env: process.env.NEXT_PUBLIC_ENV,
    version: process.env.NEXT_PUBLIC_VERSION,
  });
};

export const page = async (
  category: string,
  name: string,
  properties?: Record<string, any>,
  options?: Record<string, any>,
) => {
  const rudder = await getRudder();
  rudder.page(category, name, properties, {
    ...options,
    env: process.env.NEXT_PUBLIC_ENV,
    version: process.env.NEXT_PUBLIC_VERSION,
  });
};
