import ShowIfTrue from 'components/showIfTrue/showIfTrue';
import {useLazyQuery} from 'hooks/useQuery';
import useSessionUser from 'hooks/useSessionUser';
import {
  getFeatureGates as GetFeatureGates,
  GetFeatureGatesData,
} from 'queries/seller';
import {useEffect, useMemo} from 'react';
import {FeatureGate as FeatureGateType, User} from 'types';

export enum FeatureCode {
  'ramping' = 'RAMPING',
  'rampingUpgrade' = 'RAMPING_UPGRADE',
  'crmConnection' = 'CRM_CONNECTION',
  'xeroIntegration' = 'XERO_INTEGRATION',
  'multipleCurrencies' = 'MULTIPLE_CURRENCIES',
  'salesbricksSubscriptionManagement' = 'SALESBRICKS_SUBSCRIPTION_MANAGEMENT',
  'freePlan' = 'FREE_PLAN',
  'oktaSso' = 'OKTA_SSO',
  'taxRemitReports' = 'TAX_REMIT_REPORTS',
  'preCommitment' = 'PRE_COMMITMENT',
  'usageCredits' = 'USAGE_CREDITS',
  'exportPreviewOrderForm' = 'EXPORT_PREVIEW_ORDER_FORM',
  'slackIntegration' = 'SLACK_INTEGRATION',
  'orderLevelPricing' = 'ORDER_LEVEL_PRICING',
  'flexibleBilling' = 'FLEXIBLE_BILLING',
  'conversightCustomerDashboard' = 'CONVERSIGHT_CUSTOMER_DASHBOARD',
  'percentageBasedPricing' = 'PERCENTAGE_BASED_PRICING',
  'achOnlyFeature' = 'ACH_ONLY_FEATURE',
  'milestoneScheduleType' = 'MILESTONE_SCHEDULE_TYPE',
  'revenueWaterfallReport' = 'REVENUE_WATERFALL_REPORT',
  'billingAndPayments' = 'BILLING_AND_PAYMENTS',
  'pricingPage' = 'PRICING_PAGE',
  'revenueReport' = 'REVENUE_REPORT',
  'invoiceReceivablesReport' = 'INVOICE_RECEIVABLES_REPORT',
  'rawDataReport' = 'RAW_DATA_REPORT',
  'trials' = 'TRIALS',
  'renewalReport' = 'RENEWAL_REPORT',
  'pricingBreakdown' = 'PRICING_BREAKDOWN',
  'customerCountReport' = 'CUSTOMER_COUNT_REPORT',
  'bookingsReport' = 'BOOKINGS_REPORT',
  'globalAcceptedPaymentMethodsConfig' = 'GLOBAL_ACCEPTED_PAYMENT_METHODS_CONFIG',
  'manageMigratedOrders' = 'MANAGE_MIGRATED_ORDERS',
  'reportsRevenueChart' = 'REPORTS_REVENUE_CHART',
  'reportsBookingsChart' = 'REPORTS_BOOKINGS_CHART',
  'reportsCustomersChart' = 'REPORTS_CUSTOMERS_CHART',
  'reportsRenewalChart' = 'REPORTS_RENEWAL_CHART',
  'reportsInvoicingAndPaymentsChart' = 'REPORTS_INVOICING_AND_PAYMENTS_CHART',
  'reportsCollectionsChart' = 'REPORTS_COLLECTIONS_CHART',
  'paywallAutoPayEnrollment' = 'PAYWALL_AUTO_PAY_ENROLLMENT',
  'slackNotificationMilestonePaymentRollup' = 'SLACK_NOTIFICATION_MILESTONE_PAYMENT_ROLLUP',
  'customDomainEmailNotifications' = 'CUSTOM_DOMAIN_EMAIL_NOTIFICATIONS',
  'customerSubscriptionManagementUrl' = 'CUSTOMER_SUBSCRIPTION_MANAGEMENT_URL',
  'userflowIntegration' = 'USERFLOW_INTEGRATION',
  'buyerCheckoutRedesign' = 'BUYER_CHECKOUT_REDESIGN',
  'pylonInAppChat' = 'PYLON_IN_APP_CHAT',
}

export const useGetFeatureGates = (
  user?: User,
  sellerIdOverride?: string,
): FeatureGateType[] => {
  const [getFeatureGates, {data, error}] = useLazyQuery<GetFeatureGatesData>(
    GetFeatureGates,
    {
      variables: {sellerId: sellerIdOverride},
    },
  );
  useEffect(() => {
    if (!user && !sellerIdOverride) {
      return;
    }
    getFeatureGates();
  }, [user?.id, sellerIdOverride]);

  if (error) {
    console.error('error getting feature gates', error);
  }
  return data?.featureGates;
};

const check = (
  featureCode: FeatureCode,
  featureGates: FeatureGateType[],
  isAdmin: boolean,
): boolean => {
  // freePlan as it does not have a seller login so it is an exception and globally allowed
  if (featureCode === FeatureCode.freePlan) {
    return true;
  }

  const featureGate = featureGates.find((fg) => fg.featureCode === featureCode);
  if (!featureGate) {
    return false;
  }

  if (featureGate.enabled) {
    if (featureGate.adminOnly) {
      return isAdmin;
    } else {
      return true;
    }
  }

  return false;
};

/**
 * Returns true, false, or null if API request is in load state.
 */
export const useCheckPermissions = (
  featureCode: FeatureCode,
  sellerIdOverride?: string,
): boolean | null => {
  const [user] = useSessionUser();
  const isAdmin = user?.employment?.isCompanyAdmin;

  const featureGates = useGetFeatureGates(user, sellerIdOverride);

  return useMemo(() => {
    // freePlan as it does not have a seller login so it is an exception and globally allowed
    const isFreePlan = featureCode === FeatureCode.freePlan;

    if (isFreePlan) {
      return true;
    }
    // if featureGates has no value yet, return null
    // so we can differentiate it from boolean values.
    if (featureGates === null || featureGates === undefined) {
      return null;
    }
    return check(featureCode, featureGates, isAdmin);
  }, [featureCode, featureGates, isAdmin]);
};

interface FeatureGateProps {
  featureCode: FeatureCode;
  children: React.ReactNode;
  sellerIdOverride?: string;
}

const FeatureGate = ({
  featureCode,
  children,
  sellerIdOverride,
}: FeatureGateProps) => {
  const allowed = useCheckPermissions(featureCode, sellerIdOverride);

  return <ShowIfTrue condition={allowed}>{children}</ShowIfTrue>;
};

export const InverseFeatureGate = (props: {
  featureCode: FeatureCode;
  children: React.ReactNode;
  sellerIdOverride?: string | null;
}) => {
  const {featureCode, sellerIdOverride, children} = props;
  const allowed = useCheckPermissions(featureCode, sellerIdOverride);
  if (allowed) {
    return null;
  }
  return <>{children}</>;
};

export default FeatureGate;
