import React, {ReactNode} from 'react';
import ShowIfTrue from 'components/showIfTrue/showIfTrue';
import {useIsMobile} from 'hooks/useIsMobile';

export const ShowIfMobile = (props: {
  children: ReactNode;
  displayCondition?: boolean;
}) => {
  const {displayCondition = true} = props;
  const isMobile = useIsMobile();
  return (
    <ShowIfTrue condition={isMobile && displayCondition}>
      {props.children}
    </ShowIfTrue>
  );
};

export const ShowIfDesktop = (props: {
  children: ReactNode;
  displayCondition?: boolean;
}) => {
  const {displayCondition = true} = props;
  const isMobile = useIsMobile();
  return (
    <ShowIfTrue condition={!isMobile && displayCondition}>
      {props.children}
    </ShowIfTrue>
  );
};
